import { HeadingPrimaryMd } from '/components/buildingBlocks/Heading'
import { ButtonLinkPrimary } from '/components/buildingBlocks/Button'
import { useRelativeScroll } from '/machinery/useRelativeScroll'
import { TextPrimaryMd } from '/components/buildingBlocks/Text'
import { animated } from 'react-spring'
import { routeMap } from '/routeMap'

import backgroundImage from '/images/aston-martin.jpg'

import cssStyleContext from '/cssGlobal/style-context.css'
import styles from './ImageWithQuote.css'

export function ImageWithQuoteCorporate({ layoutClassName }) {
  return (
    <ImageWithQuoteBase
      containerStyles={styles.containerMd}
      alignment='right'
      renderContent={() => (
        <div className={styles.content}>
          <HeadingPrimaryMd h={4} heading='Global Talent Matching Partner - Aston Martin Aramco Formula One® Team' />
          <TextPrimaryMd>
            Introducing Circle8's collaboration with the Aston Martin F1® Team—an
            alliance built on shared values of excellence and innovation.
            Accelerate your future with Circle8, where opportunity meets ambition.
          </TextPrimaryMd>
        </div>
      )}
      renderCta={() => (
        <ButtonLinkPrimary href={routeMap.collab()}>
          More about Aston Martin F1®-partnership
        </ButtonLinkPrimary>
      )}
      layoutClassName={cx(styles.imageQuoteLayout, layoutClassName)}
    />
  )
}

export function ImageWithQuoteCollab({ layoutClassName }) {
  return (
    <ImageWithQuoteBase
      containerStyles={styles.containerLg}
      domain='formula1'
      alignment='left'
      renderContent={() => (
        <q className={styles.quote}>
          We are both ambitious, eager and want to become champions in our area.
        </q>
      )}
      renderCta={() => (
        <ButtonLinkPrimary target='_blank' href={routeMap.home()}>
          More about Circle8
        </ButtonLinkPrimary>
      )}
      layoutClassName={cx(styles.imageQuoteLayout, layoutClassName)}
    />
  )
}

function ImageWithQuoteBase({
  renderContent,
  renderCta,
  alignment = 'left',
  styleContext = cssStyleContext.contextDark,
  layoutClassName = undefined,
  containerStyles = undefined,
  domain = undefined
}) {
  const { ref: scrollRef, value } = useRelativeScroll()

  return (
    <div
      ref={scrollRef}
      data-domain={domain}
      data-style-context={styleContext}
      className={cx(styles.componentBase, layoutClassName)}
    >
      <animated.img
        alt=''
        role='presentation'
        src={backgroundImage}
        className={styles.image}
        style={{ y: value.to(x => `-${x * 10}px`) }}
      />

      <div className={cx(styles.container, containerStyles && containerStyles)}>
        <div className={cx(styles.inner, alignment === 'left' && styles.isLeftAligned)}>
          <div className={styles.contentLayout}>
            {renderContent()}
          </div>

          <div className={styles.ctaLayout}>
            {renderCta()}
          </div>
        </div>
      </div>
    </div>
  )
}
